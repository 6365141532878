<template>
  <div>
    <ejs-gantt
      v-if="isLoaded1"
      ref="gantt"
      id="GanttContainer"
      :dataSource="data"
      :taskFields="taskFields"
      :columns="columns"
      :height="'100vh'"
      :treeColumnIndex="1"
      :editSettings="editSettings"
      :loadChildOnDemand="false"
      :toolbar="toolbar"
      :taskMode="taskMode"
      :allowResizing="true"
    >
    </ejs-gantt>
  </div>
</template>
<script>
import Vue from "vue";
import {
  GanttPlugin,
  Edit,
  Selection,
  Toolbar,
  Resize,
} from "@syncfusion/ej2-vue-gantt";
import { mapGetters } from "vuex";
import { registerLicense } from "@syncfusion/ej2-base";
import LICIENSE from "../../../utils/LICIENSE";
import DecodeJWT from "../DecodeJWT";
Vue.use(GanttPlugin);
registerLicense(LICIENSE);
export default {
  data: function () {
    return {
      data: [],
      taskMode: "Auto",
      taskFields: {
        id: "TaskID",
        name: "TaskName",
        startDate: "StartDate",
        duration: "Duration",
        progress: "Progress",
        endDate: "EndDate",
        dependency: "Predecessor",
        child: "subtasks",
      },
      editSettings: {
        allowEditing: true,
        allowDeleting: true,
        allowTaskbarEditing: true,
        showDeleteConfirmDialog: true,
      },
      columns: [
        { headerText: "Job", field: "JobHead_JobNum" },
        { headerText: "Opr", field: "JobOper_OprSeq" },
        { headerText: "Operation Description", field: "JobOper_OpDesc" },
        { headerText: "Start Date", field: "JobHead_StartDate" },
        { headerText: "Start Hour", field: "JobHead_StartHour" },
        { headerText: "Due Date", field: "JobHead_DueDate" },
        { headerText: "Due Hour", field: "JobHead_DueHour" },
        { headerText: "Start", field: "JobOper_StartDate" },
        { headerText: "StartHour", field: "JobOper_StartHour" },
        { headerText: "Due", field: "JobOper_DueDate" },
        { headerText: "DueHour", field: "JobOper_DueHour" },
        { headerText: "LoadHour", field: "JobOper_LoadHour" },
        { headerText: "Scheduling Code", field: "JobHead_SchedCode" },
      ],
      isLoaded: false,
      isLoaded1: false,
      toolbar: [
        // "Edit",
        // "Update",
        // "Delete",
        "Cancel",
        "ExpandAll",
        "CollapseAll",
        "Search",
        "ZoomIn",
        "ZoomOut",
        "ZoomToFit",
      ],
    };
  },
  mounted() {
    let token = localStorage.getItem("MTNToken");
    if (token == "" || !token) {
      this.$router.push("/login");
    } else {
      var info = DecodeJWT(token);
      if (info.exp <= new Date().getTime() / 1000) {
        this.$router.push("/login");
        localStorage.setItem("MTNToken", "");
      } else {
        if (this.getJobSelecteds.length < 1) {
          alert("Please select Job first!");
          this.$router.push("/job-selection");
        } else if (!this.isLoaded) this.loadData();
      }
    }
  },
  watch: {
    isLoaded1() {
      if (this.isLoaded1) {
        setTimeout(() => {
          let list = document.getElementsByTagName("div");
          for (let i = list.length - 1; i > 0; i--) {
            let item = list[i];
            let text = item.innerHTML;
            if (
              text.includes("This application was built") ||
              text.includes("Syncfusion")
            ) {
              item.style.display = "none";
            }
          }
        }, 200);
      }
    },
  },
  computed: {
    ...mapGetters("Data", ["getJobSelecteds", "getEquipments", "getResources"]),
  },
  provide: {
    gantt: [Edit, Selection, Toolbar, Resize],
  },
  methods: {
    loadData() {
      this.data = [];
      let c = 1;
      this.getJobSelecteds.forEach((el, idx) => {
        let jobNum = el.Calculated_IsPreventJob
          ? "*" + el.JobHead_JobNum
          : el.JobHead_JobNum;
        let check = this.checkExist(this.data, el, idx);

        if (check) {
          this.data.forEach((el1, idx1) => {
            if (el1.TaskName == jobNum) {
              let subtasks = [];
              if (el1.subtasks) subtasks = el1.subtasks;
              const [day, month, year] =
                el.JobOper_StartDate.split("/").map(Number);
              const [day1, month1, year1] =
                el.JobOper_DueDate.split("/").map(Number);
              let subduration = this.absDateTime(
                new Date(
                  new Date(`${year}-${month}-${day}`).setHours(
                    el.JobOper_StartHour
                  )
                ),
                new Date(
                  new Date(`${year1}-${month1}-${day1}`).setHours(
                    el.JobOper_DueHour
                  )
                )
              );

              subtasks.push({
                TaskID: el1.TaskID,
                TaskName: `${el.JobOper_OprSeq}: ${el.JobOper_OpDesc}`,
                StartDate: new Date(
                  new Date(`${year}-${month}-${day}`).setHours(
                    el.JobOper_StartHour
                  )
                ),
                Duration: subduration,
                JobHead_JobNum: el.JobHead_JobNum,
                JobHead_StartDate: el.JobHead_StartDate,
                JobHead_StartHour: el.JobHead_StartHour,
                JobHead_DueDate: el.JobHead_DueDate,
                JobHead_DueHour: el.JobHead_DueHour,
                JobOper_OprSeq: el.JobOper_OprSeq,
                JobOper_OpDesc: el.JobOper_OpDesc,
                JobOper_StartDate: el.JobOper_StartDate,
                JobOper_StartHour: el.JobOper_StartHour,
                JobOper_DueDate: el.JobOper_DueDate,
                JobOper_DueHour: el.JobOper_DueHour,
                JobOper_LoadHour: el.JobOper_LoadHour,
                JobHead_SchedCode: el.JobHead_SchedCode,
              });
              let duration = 0;
              subtasks.forEach((el) => {
                duration += el.Duration;
              });
              this.data[idx1] = {
                ...el1,
                style: {
                  backgroundColor: "red",
                },
                EndDate: new Date(
                  this.data[idx1].StartDate.getHours() + duration * 24
                ),
                subtasks: subtasks,
                Duration: duration,
              };
            }
          });
        } else {
          const [day, month, year] =
            el.JobOper_StartDate.split("/").map(Number);
          const [day1, month1, year1] =
            el.JobOper_DueDate.split("/").map(Number);
          const [day2, month2, year2] =
            el.JobHead_StartDate.split("/").map(Number);
          const [day3, month3, year3] =
            el.JobHead_DueDate.split("/").map(Number);
          let duration = this.absDateTime(
            new Date(
              new Date(`${year2}-${month2}-${day2}`).setHours(
                el.JobHead_StartHour
              )
            ),
            new Date(
              new Date(`${year3}-${month3}-${day3}`).setHours(
                el.JobHead_DueHour
              )
            )
          );
          let subduration = this.absDateTime(
            new Date(
              new Date(`${year}-${month}-${day}`).setHours(el.JobOper_StartHour)
            ),
            new Date(
              new Date(`${year1}-${month1}-${day1}`).setHours(
                el.JobOper_DueHour
              )
            )
          );
          let subtasks = [];
          subtasks.push({
            TaskID: c,
            TaskName: `${el.JobOper_OprSeq}: ${el.JobOper_OpDesc}`,
            StartDate: new Date(
              new Date(`${year}-${month}-${day}`).setHours(el.JobOper_StartHour)
            ),
            Duration: subduration,
            // JobHead_JobNum: el.JobHead_JobNum,
            JobHead_StartDate: el.JobHead_StartDate,
            JobHead_StartHour: el.JobHead_StartHour,
            JobHead_DueDate: el.JobHead_DueDate,
            JobHead_DueHour: el.JobHead_DueHour,
            JobOper_OprSeq: el.JobOper_OprSeq,
            JobOper_OpDesc: el.JobOper_OpDesc,
            JobOper_StartDate: el.JobOper_StartDate,
            JobOper_StartHour: el.JobOper_StartHour,
            JobOper_DueDate: el.JobOper_DueDate,
            JobOper_DueHour: el.JobOper_DueHour,
            JobOper_LoadHour: el.JobOper_LoadHour,
            JobHead_SchedCode: el.JobHead_SchedCode,
            isParent: false,
          });
          this.data.push({
            JobHead_JobNum: el.JobHead_JobNum,
            JobHead_StartDate: el.JobHead_StartDate,
            JobHead_StartHour: el.JobHead_StartHour,
            JobHead_DueDate: el.JobHead_DueDate,
            JobHead_DueHour: el.JobHead_DueHour,
            // JobOper_OprSeq: el.JobOper_OprSeq,
            // JobOper_OpDesc: el.JobOper_OpDesc,
            // JobOper_StartDate: el.JobOper_StartDate,
            // JobOper_StartHour: el.JobOper_StartHour,
            // JobOper_DueDate: el.JobOper_DueDate,
            // JobOper_DueHour: el.JobOper_DueHour,
            // JobOper_LoadHour: el.JobOper_LoadHour,
            JobHead_SchedCode: el.JobHead_SchedCode,
            TaskID: c,
            TaskName: jobNum,
            StartDate: new Date(
              new Date(`${year}-${month}-${day}`).setHours(el.JobOper_StartHour)
            ),
            RealStartDate: new Date(
              new Date(`${year}-${month}-${day}`).setHours(el.JobOper_StartHour)
            ),
            EndDate: new Date(
              new Date(
                new Date(`${year}-${month}-${day}`).setHours(
                  el.JobOper_StartHour
                )
              ).getHours() +
                duration * 24
            ),
            Duration: duration,
            subtasks,
          });
          c++;
        }
      });
      this.isLoaded = true;
      this.isLoaded1 = true;
      let a = document.getElementsByClassName("e-headercell");
      a[0].bgColor = "red";
    },
    absDateTime(date1, date2) {
      return Math.abs(date2 - date1) / (1000 * 60 * 60 * 24);
    },
    checkExist(arr, item) {
      let jobNum = item.Calculated_IsPreventJob
        ? "*" + item.JobHead_JobNum
        : item.JobHead_JobNum;
      if (arr.length < 1) return false;
      let check = 0;
      arr.forEach((el) => {
        if (el.TaskName == jobNum) {
          check = 1;
          return;
        }
      });
      return check ? true : false;
    },
  },
};
</script>
<style lang="scss">
#GanttContainer {
  width: auto;
  height: 100vh !important;
  .e-gantt-toolbar,
  .e-toolbar-item,
  .e-tbar-btn,
  .e-columnheader,
  .e-timeline-header-container,
  .treeGridGanttContainer_gridcontrolcolgroup,
  .e-gantt-splitter,
  .e-headercell,
  .e-rhandler,
  .e-timeline-top-header-cell {
    background-color: #c1e4f7 !important;
    border-color: black !important;
    color: black !important;
  }
  .e-row {
    border-color: black !important;
  }
  .e-gantt-child-taskbar-inner-div {
    background-color: #ffd30e !important;
  }
  .e-gantt-parent-taskbar {
    background-color: #2661b7 !important;
  }
  .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
    height: 65px !important;
  }

  .table td {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .v-data-table {
    overflow-x: auto;
    overflow-y: auto;
  }

  tr:nth-child(even) {
    background-color: #edf9ff;
    white-space: nowrap;
  }

  tr:nth-child(odd) {
    white-space: nowrap;
  }

  table,
  th,
  td {
    border-collapse: collapse;
  }

  th {
    border-bottom: 2px solid black;
  }

  td {
    text-align: center;
  }
}
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-layouts/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-treegrid/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-gantt/styles/material.css";
@import "../../../assets/css/standard.scss";
</style>
